<template>
    <div class="mainwidth">
        <div style="font-size: 18px;text-align: center;font-weight: bold;">
            <a href="http://www.fanshichanrong.com:9608">点击进入</a>
        </div>
       <div class="font-size">
        为深入挖掘贷款客户资源，实现龙哈智能产融综合服务平台合作金融机构贷后的精细化管理，及贷后管理业务的高效运行，龙哈智能产融综合服务平台以数据互通、业务系统为基础，突出数据资源整合、多方业务协同、多渠道数据整合，搭建“快捷、高效”的担保贷款服务应用，提高金融机构工作效率，降低管理成本。
       </div>
       <div class="font-size">
        贷后监管系统通过对数据、流程、指标标签化体系、舆情时讯等模块进行智能化管理，实时反馈企业客户贷后预警信息，为银行各管理层级提供灵活便捷的贷后监管服务。
       </div>
    </div>
</template>

<script>
export default {
    name: 'Supervision'
}
</script>

<style lang="less" scoped>
.mainwidth{
    padding-top: 20px;
    padding-bottom: 20px;
}
.font-size{
    font-size: 18px;
    margin-top: 20px;
    text-indent: 2em;
}
</style>
